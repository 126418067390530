import React from "react";
import styled from "styled-components";
import { CalendarDays, ChevronsUp } from "lucide-react";
import ManualSyncSvg from "assets/icons/manual-sync.svg";
import { spectrum } from "styles/theme";
import { BREAKPOINTS } from "components/docs/assets/constants";
import DocumentationSection from "../../components/docs/DocumentationSection";
import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import HeaderBar from "../../components/docs/layout/HeaderBar";
import DocsHelmet from "../../components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "../../components/docs/shared-components/PageContainers";
import { RouterLocation } from "../../types/types";

interface Props {
  location: RouterLocation;
}

const SyncFrequncySection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1px;
  border-radius: 8px;
  background-color: #f1f3f4;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

  @media (max-width: ${BREAKPOINTS.SM}px) {
    flex-wrap: wrap;
  }
`;

const CardSection = styled.div<{ $width: string }>`
  position: relative;
  z-index: 100;
  width: ${({ $width }) => $width ?? "25%"};
  display: flex;
  flex-direction: column;
  background-color: #f1f3f4;
  gap: 1px;
  border-radius: 8px;

  @media (max-width: ${BREAKPOINTS.SM}px) {
    width: 100%;
  }
`;

const CardHeader = styled.div<{ $width?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 6px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 2px #f1f3f4;
  ${({ $width }) => ($width ? `width: ${$width};` : "width: 100%;")}
  background-color: #ffffff;
`;

const CardBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 12px 16px 16px;
  gap: 8px;
  box-shadow: 0px 0px 2px #f1f3f4;
  background-color: #ffffff;
`;

const SyncFrequencyBadge = styled.div<{ $color: string }>`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${({ $color }) => $color};
  gap: 8px;
`;

const SyncPlanBadge = styled.div<{ $color: string; $bg: string }>`
  color: ${({ $color }) => $color};
  background: ${({ $bg }) => $bg};
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  border-radius: 4px;
  padding: 2px 5px;
  display: flex;
  align-items: flex-end;
`;

const SectionDivider = styled.div`
  padding-top: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--gray10);
  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
`;

export const SyncFrequencyOverviewTable = () => (
  <SyncFrequncySection className="mt-9">
    <CardSection $width="25%">
      <CardHeader>
        <SyncFrequencyBadge $color="#F97316">
          <ChevronsUp size={16} />
          Highest
        </SyncFrequencyBadge>
        <SyncPlanBadge $color="#1A9B87" $bg="#EBFCFA">
          Automatic
        </SyncPlanBadge>
      </CardHeader>
      <CardBody style={{ borderRadius: "0px 0px 0px 8px" }}>
        Data syncs at the highest frequency supported by Merge.
      </CardBody>
    </CardSection>
    <CardSection $width="25%">
      <CardHeader>
        <SyncFrequencyBadge $color="#D9A800">
          <CalendarDays size={16} />
          Daily
        </SyncFrequencyBadge>
        <SyncPlanBadge $color={spectrum.teal50} $bg={spectrum.teal0}>
          Automatic
        </SyncPlanBadge>
      </CardHeader>
      <CardBody>Data syncs every 24 hours.</CardBody>
    </CardSection>
    <CardSection $width="50%">
      <div className="d-flex" style={{ display: "flex", flexDirection: "row", gap: "1px" }}>
        <CardHeader $width="50%">
          <SyncFrequencyBadge $color="#737982">
            <ManualSyncSvg />
            Monthly
          </SyncFrequencyBadge>
          <SyncPlanBadge $color="#50545B" $bg="#F2F4F7">
            Manual
          </SyncPlanBadge>
        </CardHeader>
        <CardHeader $width="50%" className="overflow-auto">
          <SyncFrequencyBadge $color="#737982">
            <ManualSyncSvg />
            Quarterly
          </SyncFrequencyBadge>
          <SyncPlanBadge $color="#50545B" $bg="#F2F4F7">
            Manual
          </SyncPlanBadge>
        </CardHeader>
      </div>
      <CardBody style={{ borderRadius: "0px 0px 8px 0px" }}>
        Control when you sync data either in your Merge dashboard or via API. Resyncs are limited
        based on your allocated amount of sync credits.
      </CardBody>
    </CardSection>
  </SyncFrequncySection>
);

function DocumentationSyncFrequencyPage({ location }: Props) {
  const title = "Sync Frequency";
  const description =
    "Learn about the different sync frequencies for data from third parties to Merge.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview">
        <p>
          Sync frequency is the rate at which Merge initiates requests to fetch data from
          third-parties. Actual time to complete a sync can vary depending on the third-party.
        </p>
        <p>
          You can view your sync frequency in the{" "}
          <a href="https://app.merge.dev/integrations">Integrations</a> section of your Merge
          dashboard. <a href="mailto: support@merge.dev">Contact us</a> for more information.
        </p>

        <SyncFrequencyOverviewTable />
      </DocumentationSection>

      <SectionDivider />

      <DocumentationSection title="Sync Credits">
        <p>
          Customers who select Manual sync plans (<b>Monthly</b> or <b>Quarterly</b>) will be
          allocated sync credits that apply per Linked Account, including bonus sync credits for
          mistakes and changes.
        </p>
        <p>
          A sync credit applies when you resync data for a Linked Account in the Merge dashboard or
          via API. The initial sync for a Linked Account does not use a sync credit.
        </p>
        <p>
          Sync credits renew each year, starting on the day that each Linked Account begins syncing.
        </p>
      </DocumentationSection>

      <br />

      <CrossGuideLinks location={location} style={{ marginTop: "12px " }} />
    </StaticPageContainer>
  );
}

export default DocumentationSyncFrequencyPage;
