import React from "react";
import Markdown from "markdown-to-jsx";
import { OverlayTrigger, Row } from "react-bootstrap";
import styled from "styled-components";
import { ChevronRight } from "lucide-react";

const Badge = styled.span`
  color: #939aa5;
`;

interface ParameterRowProps {
  name: string;
  type: string;
  description?: string;
  hoverOverlay?: JSX.Element;
  children?: JSX.Element | undefined;
  viewGuideUrl?: string;
}

const AuthParameterRow = ({
  name,
  type,
  description,
  hoverOverlay,
  children,
  viewGuideUrl,
}: ParameterRowProps) => {
  const getRow = (
    <div>
      <Row className="py-1 mx-0">
        <div className="flex justify-between w-full text-sm leading-5">
          <div className="flex items-center">
            <div className="text-left" style={{ width: "200px" }}>
              <div>
                <code>{name}</code>
                <Badge className="font-semibold text-sm leading-4 ml-1.5">{type}</Badge>
              </div>
            </div>
            <div className="flex-1 ml-16 text-14" style={{ width: "436px" }}>
              {description && <Markdown>{description}</Markdown>}
            </div>
          </div>
          <div className="text-right">
            {viewGuideUrl && (
              <div className="text-blue-40 text-[12px]">
                <a href={viewGuideUrl} target="_blank">
                  View guide
                </a>
                <ChevronRight size={12} />
              </div>
            )}
          </div>
        </div>
      </Row>
      {children}
    </div>
  );

  return (
    <>
      <hr />
      {hoverOverlay ? (
        <OverlayTrigger placement="left" delay={{ show: 250, hide: 0 }} overlay={hoverOverlay}>
          {getRow}
        </OverlayTrigger>
      ) : (
        getRow
      )}
    </>
  );
};

export default AuthParameterRow;
