import React from "react";
import NavigationScrollTracker from "../navigation/NavigationScrollTracker";
import { RelatedUseCaseWorkflowType } from "types/UseCaseWorkflowTypes";
import AnchorHeading from "../shared-components/AnchorHeading";
import { ChevronRight } from "lucide-react";
import RelatedUseCases from "templates/docs/marketing/components/RelatedUseCases";
type UseCaseOverviewSectionProps = {
  useCases: RelatedUseCaseWorkflowType[];
  categoryName: string;
};
const UseCaseOverviewSection = ({ useCases, categoryName }: UseCaseOverviewSectionProps) => {
  return (
    <div>
      <div className="flex items-center mb-3">
        <div className="flex-1">
          <NavigationScrollTracker trackId={"use-cases"}>
            <AnchorHeading
              headingLevel={"h4"}
              anchorSlug={"use-cases"}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              Use cases
            </AnchorHeading>
          </NavigationScrollTracker>
        </div>
        <div className="self-end">
          <a
            href="https://docs.merge.dev/use-cases/"
            target="_blank"
            className="text-[12px] font-medium"
          >
            View all use cases <ChevronRight size={12} />
          </a>
        </div>
      </div>
      <div className="mb-6">
        Look through use cases featuring {categoryName} to understand how your product works with
        Merge.
      </div>
      <RelatedUseCases relatedUseCases={useCases} showTitle={false} />
    </div>
  );
};

export default UseCaseOverviewSection;
