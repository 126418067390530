import {
  APICategory,
  doesPathItemObjectHaveTag,
  getDefinitionForModelName,
  getModelName,
  hydratedParametersFromModelDefinition,
} from "@merge-api/merge-javascript-shared";
import { graphql } from "gatsby";
import { hydrateAvailableActions, useAvailableActions } from "hooks/useAvailableActions";
import type { OpenAPIV3 } from "openapi-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { spectrum } from "styles/theme";
import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import PathDocumentationSection from "../../components/docs/PathDocumentationSection";
import CommonModelSection from "../../components/docs/sections/CommonModelSection";
import OverviewSection from "../../components/docs/sections/OverviewSection";
import Spinner from "../../components/docs/shared-components/Spinner";
import { RouterLocation } from "../../types/types";
import { ADDITIONAL_ENDPOINT_INFO_MAP } from "components/docs/assets/constants";
import EndpointAlertBlock from "components/docs/EndpointAlertBlock";
import isEmpty from "lodash/isEmpty";
const MODEL_NAME_OVERRIDE_MAP: Record<string, Record<string, string>> = {
  mktg: { Email: "MarketingEmail" },
};

const CODE_STYLE = css`
  font-family: var(--font-family-monospace);
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  background: ${spectrum.gray0};
  padding: 1px 5px;
  border-radius: 3px;
`;

const PaddedRow = styled(Row)`
  && {
    @media (max-width: 767.96px) {
      padding: 40px 4px;
    }
    code {
      ${CODE_STYLE};
    }
    padding: 40px;
    margin: 0 auto;
    width: 100%;
    display: flex;
  }
`;

const PaddedCol = styled(Col)`
  && {
    @media (max-width: 767.96px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

export const query = graphql`
  query CategorySectionPageQuery($category: String!) {
    schemas(category: { eq: $category }) {
      schema
    }
  }
`;

interface CategorySectionPageProps {
  pageContext: {
    category: APICategory;
    tag?: string;
  };
  data: {
    schemas: {
      schema: OpenAPIV3.Document;
    };
  };
  location: RouterLocation;
}

const CategorySectionPage = ({
  pageContext: { category, tag },
  data: { schemas },
  location,
}: CategorySectionPageProps) => {
  const allAvailableActions = useAvailableActions();
  if (tag === undefined || tag === "overview") {
    return (
      <PaddedRow>
        <PaddedCol>
          <OverviewSection category={category} location={location} />
        </PaddedCol>
      </PaddedRow>
    );
  }

  /**
   * Type guard for grabbing only the defined path item objects in a filter +
   * making sure it has a tag
   */
  const pathItemObjectHasTag = (
    entry: [string, OpenAPIV3.PathItemObject | undefined],
  ): entry is [string, OpenAPIV3.PathItemObject] =>
    !!entry[1] && doesPathItemObjectHaveTag(entry[1], tag);

  const { schema } = schemas;
  let modelName = getModelName(tag, category);
  const categoryString: string = APICategory[category];
  if (MODEL_NAME_OVERRIDE_MAP[categoryString] && MODEL_NAME_OVERRIDE_MAP[category][modelName]) {
    modelName = MODEL_NAME_OVERRIDE_MAP[category][modelName];
  }
  const modelAvailableActions = allAvailableActions[category]?.[modelName] ?? {};
  const modelDefinition = getDefinitionForModelName(modelName, schema) ?? {};
  const pathsWithTag = Object.entries(schema.paths).filter(pathItemObjectHasTag);
  const allParameters = hydratedParametersFromModelDefinition(schema, modelDefinition);
  const expandedFieldDefinitionToModelNameMap = allParameters
    .filter(
      (item) =>
        item.hasOwnProperty("nested") &&
        item.nested &&
        item.nested.hasOwnProperty("name") &&
        item.name !== "remote_data",
    )
    .reduce(
      (acc, item) => {
        acc[item.name] = item.nested?.name;
        return acc;
      },
      {} as { [key: string]: string | undefined },
    );
  const expandedFieldDefinitionToAvailableActions = Object.keys(
    expandedFieldDefinitionToModelNameMap,
  ).reduce((acc, commonModelFieldName) => {
    return {
      ...acc,
      [commonModelFieldName]:
        allAvailableActions[category]?.[
          expandedFieldDefinitionToModelNameMap[commonModelFieldName]!
        ],
    };
  }, {});

  const hydratedActions = modelDefinition
    ? hydrateAvailableActions(
        schema,
        modelAvailableActions,
        modelDefinition,
        category,
        expandedFieldDefinitionToAvailableActions,
        tag,
      )
    : {};

  const additional_path_info = ADDITIONAL_ENDPOINT_INFO_MAP[tag];

  return (
    <PaddedRow>
      <PaddedCol>
        {schema ? (
          <>
            <section id={tag} key={tag} className="docs--tag-section">
              {isEmpty(modelDefinition) &&
                additional_path_info &&
                additional_path_info.title &&
                additional_path_info.subtitle && (
                  <>
                    <div className="flex flex-wrap">
                      <div className="text-[32px] mb-[18px] text-gray-90 font-semibold leading-[40px]">
                        {additional_path_info.title}
                      </div>
                    </div>
                    <div className="flex flex-wrap">{additional_path_info.subtitle}</div>
                    {additional_path_info?.pricingMessage && (
                      <div className="mt-9">
                        <EndpointAlertBlock path={tag} />
                      </div>
                    )}
                    <hr className=" my-16" />
                  </>
                )}
              <CommonModelSection
                modelDefinition={modelDefinition as any}
                document={schema}
                tag={tag}
                category={category}
              />
              {pathsWithTag.map(([path, pathItemObject]) => (
                <PathDocumentationSection
                  key={path}
                  path={path}
                  category={category}
                  pathItemObject={pathItemObject as any}
                  availableActions={hydratedActions}
                  hasMetaAvailable={!!schema.paths[`${path}/meta/post`]}
                  document={schema}
                  modelName={modelName}
                />
              ))}
            </section>
            <CrossGuideLinks location={location} />
          </>
        ) : (
          <Spinner />
        )}
      </PaddedCol>
    </PaddedRow>
  );
};

export default CategorySectionPage;
