import React from "react";
import styled from "styled-components";
import NavigationScrollTrackerWithAnchor from "../navigation/NavigationScrollTrackerWithAnchor";
import AuthParameterRow from "../AuthParameterRow";

const CurlContainer = styled.div`
  background: #00102f;
`;

type AuthenticationSectionProps = {
  category: string;
  primaryModel: string;
};
const AuthenticationSection = ({ category, primaryModel }: AuthenticationSectionProps) => {
  return (
    <NavigationScrollTrackerWithAnchor
      headingLevel="h4"
      title="Authentication"
      anchorSlug="authentication"
      sliverStartThreshold={0.15}
      style={{ marginTop: 0, marginBottom: 12 }}
    >
      <div className="mb-6">
        <div className="mb-6">
          When making requests to Merge’s Unified API, you will need to include proper
          authentication parameters in the headers for every request to Merge’s API to identify
          yourself as an authorized user.
        </div>
        <NavigationScrollTrackerWithAnchor
          headingLevel="h5"
          title="Header parameters"
          anchorSlug="header-parameters"
          sliverStartThreshold={0.15}
          style={{ marginTop: 0, marginBottom: 16 }}
        >
          <AuthParameterRow
            key={"Authorization"}
            name={"Authorization"}
            type={"String"}
            description={'Token-based authentication with required prefix "Bearer".'}
            viewGuideUrl="https://docs.merge.dev/basics/authentication/#api-key"
          />
          <AuthParameterRow
            key={"X-Account-Token"}
            name={"X-Account-Token"}
            type={"String"}
            description={
              "Token identifying the end user. This is only required for requests for data specific to a Linked Account."
            }
            viewGuideUrl="https://docs.merge.dev/basics/authentication/#account-tokens"
          />
        </NavigationScrollTrackerWithAnchor>
        <CurlContainer className="p-2.5 rounded-lg mt-4">
          <div className="font-mono text-[13px] text-white">
            <p className="mb-0 text-[13px]">
              curl --location 'https://api.merge.dev/api/{category}/v1/{primaryModel}' \
            </p>
            <p className="mb-0 text-[13px]">--header 'Accept: application/json' \</p>
            <p className="mb-0 text-[13px]">
              {`--header 'Authorization: Bearer {{production-key}}'`} \
            </p>
            <p className="mb-0 text-[13px]">{`--header 'X-Account-Token: {{account-token}}'`}</p>
          </div>
        </CurlContainer>
      </div>
    </NavigationScrollTrackerWithAnchor>
  );
};
export default AuthenticationSection;
