import React from "react";
import styled from "styled-components";
import { ChevronsUp, Calendar } from "lucide-react";
import NavigationScrollTrackerWithAnchor from "../navigation/NavigationScrollTrackerWithAnchor";
import { SyncFrequencyOverviewTable } from "pages/basics/sync-frequency";
const FlexContainer = styled.div`
  @media (max-width: 576px) {
    flex-wrap: wrap !important;
    gap: 12px;
  }
  flex-wrap: nowrap;
`;

type SyncDataOverviewSectionProps = {
  category: string;
};
const SyncDataOverviewSection = ({ category }: SyncDataOverviewSectionProps) => {
  const integrationsUrl = `https://app.merge.dev/integrations/${category}`;

  return (
    <>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h4"
        title="Syncing data"
        anchorSlug="syncing-data"
        sliverStartThreshold={0.15}
        style={{ marginTop: 0, marginBottom: 12 }}
      >
        <div className="mb-6">
          <div>
            <div className="mb-6">
              To pull data from Merge into your app, we recommend a combination of webhooks and
              polling. See our{" "}
              <a href="https://docs.merge.dev/basics/syncing-data/" target="_blank">
                syncing data guide
              </a>{" "}
              for more information.
            </div>
          </div>
          <div>
            <NavigationScrollTrackerWithAnchor
              headingLevel="h6"
              title="Query parameters"
              sliverStartThreshold={0.15}
              anchorSlug="query-parameters"
            >
              <div>
                <FlexContainer className="flex">
                  <div className="min-w-[300px]">
                    <div className="text-blue-40 font-semibold">GET</div>
                    <div className="whitespace-nowrap	">
                      {`/{endpoint}`}
                      <span className="font-semibold">?modified_after={`{timestamp}`}</span>
                    </div>
                  </div>
                  <div className="ml-3">
                    <p className="mb-2">
                      Only pull data that has been changed or created since your last sync began.{" "}
                    </p>
                    <p>
                      For example, you can ask for{" "}
                      <span className="bg-gray-0 font-mono p-1 rounded-[4px]">
                        modified_after=2021-03-30T20:44:18
                      </span>
                      , and only pull items that are new or changed.
                    </p>
                  </div>
                </FlexContainer>
                <hr className="h-[1px] mt-4 mb-4"></hr>
                <FlexContainer className="flex">
                  <div className="min-w-[300px]">
                    <div className="text-blue-40 font-semibold">GET</div>
                    <div className="whitespace-nowrap	">
                      {`/{endpoint}`}
                      <span className="font-semibold">?expand={`{model_name}`}</span>
                    </div>
                  </div>
                  <div className="ml-3">
                    <p className="mb-2">
                      If you expand fields on a Common Model, you will receive data for entire
                      related objects rather than just the IDs of those objects. Do this to avoid
                      making multiple API calls to retrieve each related object.{" "}
                    </p>
                    <p>
                      For example, if you are querying for candidates and also want details about
                      associated applications, you can{" "}
                      <span className="bg-gray-0 font-mono p-1 rounded-[4px]">
                        expand=applications
                      </span>
                      , and Merge will return the actual application objects instead of just the{" "}
                      <span className="bg-gray-0 font-mono p-1 rounded-[4px]">application_ids</span>
                      .
                    </p>
                  </div>
                </FlexContainer>
              </div>
            </NavigationScrollTrackerWithAnchor>
          </div>
        </div>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h6"
        title="Rate limits"
        anchorSlug="rate-limits"
        sliverStartThreshold={0.15}
        style={{ marginBottom: 12 }}
      >
        <div className="mb-6">
          <div className="mb-3">
            API requests to Merge's Unified API are rate limited to the following{" "}
            <span className="font-semibold">per linked account</span> (as identified by the{" "}
            <span className="bg-gray-0 font-mono p-1 rounded-[4px]">account_token</span> attached to
            the request):
          </div>
          <ul className="mb-3">
            <li className="mb-0">Launch: 100 / minute</li>
            <li className="mb-0">Professional: 400 / minute</li>
            <li className="mb-0">Enterprise: 600 / minute</li>
          </ul>
          <div>
            For example, if you have 10 customers, the rate limits above would apply to each one
            separately. Some endpoints may have different rate limits as specified throughout the
            API reference.
          </div>
        </div>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h6"
        title="Sync frequency"
        anchorSlug="sync-frequency"
        sliverStartThreshold={0.15}
        style={{ marginBottom: 12 }}
      >
        <div className="mb-6">
          Sync frequency is the rate at which Merge initiates requests to fetch data from
          third-parties. Actual time to complete a sync can vary depending on the third-party. You
          can view your sync frequency in the{" "}
          <a href={integrationsUrl} target="_blank">
            integrations
          </a>{" "}
          section of your Merge dashboard. <a href="mailto:support@merge.dev">Contact us</a> for
          more information.
        </div>
        <SyncFrequencyOverviewTable />
      </NavigationScrollTrackerWithAnchor>
    </>
  );
};

export default SyncDataOverviewSection;
