import { graphql, useStaticQuery } from "gatsby";

const worflowContainsCategory = (categories, selectedCategory) => {
  if (!categories) {
    return false;
  }
  for (const categoryInfo of categories) {
    const { category } = categoryInfo;
    if (selectedCategory.value == category) {
      return true;
    }
  }
  return false;
};

const workflowContainsIndustry = (industries, selectedIndustry) => {
  if (!industries) {
    return false;
  }
  for (const industry of industries) {
    if (selectedIndustry.value == industry.name) {
      return true;
    }
  }
  return false;
};

const workflowContainsDataModel = (useCaseDataModels, selectedDataModel) => {
  if (!useCaseDataModels) {
    return false;
  }
  for (const dataModel of useCaseDataModels) {
    if (selectedDataModel.value == dataModel.common_model_docs_slug) {
      return true;
    }
  }
  return false;
};

export const useWorkflows = (selectedCategory, selectedIndustry, selectedDataModel) => {
  const data = useStaticQuery(graphql`
    {
      allUseCaseWorkflow {
        nodes {
          workflow_identifier
          workflow_slug
          is_featured
          case_studies {
            title
            slug
            logo
            company_color
          }
          published_state
          use_case {
            name
            caveats
            categories {
              category
              subcategory
            }
            description
            industries {
              name
            }
            recommended_sync_frequency
            sfdc_status
            use_case_features {
              name
              url
            }
            use_case_models {
              common_model_docs_slug
              common_model_name
              category
              method_fields {
                methods
                fields
              }
            }
          }
          use_case_cta {
            title
            subtitle
            button_text
            url
          }
          use_case_steps {
            content
            name
            step_image {
              id
              image
              name
            }
            step_type
            feature_callout {
              content
              priority
            }
          }
          related_use_cases {
            is_featured
            published_state
            workflow_slug
            use_case {
              categories {
                category
                subcategory
              }
              name
              description
            }
          }
        }
      }
    }
  `);

  const allPublishedWorkflows = data.allUseCaseWorkflow.nodes.filter(
    (useCaseWorkflow) => useCaseWorkflow.published_state === "PUBLISHED",
  );

  const useCases = allPublishedWorkflows.filter((useCaseWorkflow) => {
    const { use_case: useCase } = useCaseWorkflow;
    if (selectedCategory && !worflowContainsCategory(useCase.categories, selectedCategory)) {
      return false;
    }

    if (selectedIndustry && !workflowContainsIndustry(useCase.industries, selectedIndustry)) {
      return false;
    }

    if (
      selectedDataModel &&
      !workflowContainsDataModel(useCase.use_case_models, selectedDataModel)
    ) {
      return false;
    }
    return true;
  });

  const featuredWorkflows = useCases.filter((useCaseWorkflow) => {
    return useCaseWorkflow.is_featured;
  });

  return {
    featuredWorkflows,
    renderedWorkflows: useCases,
    allWorkflows: allPublishedWorkflows,
  };
};
