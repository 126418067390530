import React from "react";
import { Link } from "gatsby";
import LINKS from "../navigation/links";
import styled from "styled-components";
import NavigationScrollTracker from "../navigation/NavigationScrollTracker";
import AnchorHeading from "../shared-components/AnchorHeading";
const SDKParagraphContainer = styled.div`
  @media (max-width: 990px) {
    margin-bottom: 12px;
    width: 100%;
  }
`;
const SDKOverviewSection = () => {
  return (
    <div className="flex flex-wrap">
      <SDKParagraphContainer className="flex-1">
        <NavigationScrollTracker trackId={"merge-sdks"}>
          <AnchorHeading
            headingLevel={"h4"}
            anchorSlug={"merge-sdks"}
            style={{ marginTop: 0, marginBottom: 12 }}
          >
            Merge SDKs
          </AnchorHeading>
        </NavigationScrollTracker>
        <div>
          Streamlined way to use Merge APIs, starting with reducing the boilerplate code you have to
          write. You can find the installation and usage instructions for all of our available SDK's{" "}
          <Link to={LINKS.SDKS.linkTo}>here</Link>.
        </div>
      </SDKParagraphContainer>
      <div>
        <div className="bg-gray-0 p-5 pt-4 min-w-[262px] ml-6 rounded-[10px]">
          <div className="font-semibold mb-2">Popular SDKs</div>
          <ul className="pl-5">
            <li className="mb-2">
              <a href="https://github.com/merge-api/merge-node-client/" target="_blank">
                Node
              </a>
            </li>
            <li className="mb-2">
              <a href="https://github.com/merge-api/merge-java-client/" target="_blank">
                Java (JVM)
              </a>
            </li>
            <li className="mb-2">
              <a href="https://github.com/merge-api/merge-python-client/" target="_blank">
                Python
              </a>
            </li>
            <li className="mb-2">
              <a href="https://github.com/merge-api/merge-go-client/" target="_blank">
                Go
              </a>
            </li>
            <li className="mb-2">
              <a href="https://github.com/merge-api/merge-ruby-client/" target="_blank">
                Ruby
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default SDKOverviewSection;
