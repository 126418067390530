import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { Col } from "react-bootstrap";
import { Badge } from "@merge-api/merge-javascript-shared";
import { RelatedUseCaseType } from "types/UseCaseWorkflowTypes";

type RelatedUseCaseProps = {
  useCase: RelatedUseCaseType;
  workflowSlug: string;
  background?: string;
  isFeatured?: boolean;
  showSubCategories?: boolean;
};

const ClickableCol = styled(Col)`
  @media (max-width: 576px) {
    margin-bottom: 8px;
  }
`;

const UseCaseContainer = styled.div`
  &:hover {
    cursor: pointer;
    background-color: #f5f8fb;
  }
  background-color: #ffffff;
`;
const CategoryContainer = styled.div`
  gap: 4px;
`;

const navigateToUseCase = (workflowSlug: string) => {
  navigate(`/use-cases/${workflowSlug}`);
};

const DescriptionContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.h6`
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RelatedUseCase = ({
  useCase,
  workflowSlug,
  isFeatured = false,
  showSubCategories = false,
}: RelatedUseCaseProps) => {
  const { name, description, categories } = useCase;
  return (
    <ClickableCol
      className={!isFeatured ? "mb-5 pl-0" : "mt-5 pl-0"}
      lg={4}
      md={6}
      sm={12}
      onClick={() => {
        navigateToUseCase(workflowSlug);
      }}
    >
      <UseCaseContainer className="d-flex h-100 flex-column rounded-lg shadow-md pt-3 pb-3 pl-4 pr-4">
        <div className="d-flex w-100 align-items-center ">
          <Title className="flex-grow-1 font-semibold pr-3 mb-0">{name}</Title>
          <CategoryContainer className="d-flex ml-auto">
            {categories.map((categoryInfo) => {
              return (
                <Badge size="md" color="blue">
                  {categoryInfo.category}
                </Badge>
              );
            })}
          </CategoryContainer>
        </div>
        <DescriptionContainer className="mt-3 mb-3">{description}</DescriptionContainer>
        {showSubCategories && (
          <CategoryContainer className="d-flex mt-auto">
            {categories.map((categoryInfo) => {
              const { subcategory } = categoryInfo;
              return subcategory.map((subCategoryName: string) => {
                return (
                  <Badge size="md" color="blue">
                    {subCategoryName}
                  </Badge>
                );
              });
            })}
          </CategoryContainer>
        )}
      </UseCaseContainer>
    </ClickableCol>
  );
};
export default RelatedUseCase;
