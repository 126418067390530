import React from "react";
import RelatedUseCase from "./RelatedUseCase";
import { RelatedUseCaseWorkflowType } from "types/UseCaseWorkflowTypes";

type RelatedUseCaseProps = {
  relatedUseCases: RelatedUseCaseWorkflowType[];
  showTitle?: boolean;
};
const RelatedUseCases = ({ relatedUseCases, showTitle = true }: RelatedUseCaseProps) => {
  return (
    <div>
      {showTitle && (
        <>
          <div className="mb-9 mt-9">
            <hr />
          </div>
          <h4 className="mb-5 font-semibold">Related Use Cases</h4>
        </>
      )}
      <div className="d-flex flex-wrap">
        {relatedUseCases.map((relatedUseCase) => {
          const { use_case, workflow_slug } = relatedUseCase;
          return <RelatedUseCase useCase={use_case} workflowSlug={workflow_slug} />;
        })}
      </div>
    </div>
  );
};
export default RelatedUseCases;
