import React from "react";
import NavigationScrollTrackerWithAnchor from "../navigation/NavigationScrollTrackerWithAnchor";
import { ChevronLeft, ChevronRight } from "lucide-react";

type CategoryAPIHelpOverviewSectionProps = {
  category: string;
};
const CategoryAPIHelpOverviewSection = ({ category }: CategoryAPIHelpOverviewSectionProps) => {
  const supportedFieldsUrl = `https://docs.merge.dev/integrations/${category}/supported-fields`;
  return (
    <div className="flex">
      <div className="flex-1">
        <NavigationScrollTrackerWithAnchor
          headingLevel="h4"
          title="Testing via Postman"
          sliverStartThreshold={0.15}
          pageLoadOffsetInPx={-220}
          anchorSlug="testing-via-postman_supported-integration-fields"
          style={{ marginTop: 0, marginBottom: 12 }}
        >
          <p className="mb-0">Use Merge&apos;s public Postman workspace to get started fast.</p>
          <p className="mb-[16px]">
            Learn best practices for testing in our{" "}
            <a href="https://docs.merge.dev/guides/testing-via-postman/" target="_blank">
              guide
            </a>
            .
          </p>
          <a
            target="_blank"
            href="https://www.postman.com/mergeapi/workspace/merge-public-workspace/overview"
            rel="noreferrer"
          >
            <span aria-label="Run in Postman" role="button">
              <img src="https://run.pstmn.io/button.svg" alt="" />
            </span>
          </a>
        </NavigationScrollTrackerWithAnchor>
      </div>
      <div className="p-5 pt-4 pb-4 bg-gray-0 rounded-[10px] flex-1 h-fit">
        <div className="flex items-center justify-between mb-3">
          <div className="font-semibold">Supported integration fields</div>
          <a href={supportedFieldsUrl} target="_blank">
            <span className="font-medium text-[12px]">View fields</span>
            <ChevronRight size={12} />
          </a>
        </div>
        <div>
          See which fields are supported for each integration and compare coverage across platforms.
        </div>
      </div>
    </div>
  );
};
export default CategoryAPIHelpOverviewSection;
